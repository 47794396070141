/* picking-list */
@page {
  size: A4 landscape;
  margin: 0;
}

body, #root {
  display: block !important;
}

body {
  counter-reset: page-number;
}

.page {
  box-sizing: border-box;
  padding: 10mm;
  font-size: 11pt;
  background-color: #ffffff;
  color: #000000;
	page-break-after: always;
}

.page:last-child {
	page-break-after: auto;
}

h1 {
  margin: 5px;
  text-align: center;
  font-size: 12pt;
  background-color: #cccccc;
}

.page tr {
  page-break-inside: avoid;
}

.page .box {
  margin: 10mm;
}

.page .flex {
  display: flex;
  justify-content: space-between;
}

.page table {
  border-collapse: collapse;
  border-top: 1px solid #444444;
  border-left: 1px solid #444444;
  margin: 0 5mm;
}

.page thead {
  page-break-inside: avoid;
}

.page tr {
  page-break-inside: avoid;
}

.page th {
  text-align: left;
  padding: 2px;
  border-right: 1px solid #444444;
  border-bottom: 1px solid #444444;
}

.page td {
  text-align: left;
  padding: 2px;
  border-right: 1px solid #444444;
  border-bottom: 1px solid #444444;
}

.page .textRight {
  text-align: right;
}

.page .summery {
  background-color: #f0f0f0;
}
